<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div class="weui-tab">
        <div class="weui-tab__bd">
          <div
            id="tab2"
            class="
              weui-tab__bd-item weui-tab__bd-item--active
              public-prizes-historys
            "
          >
            <div class="public-prizes-historys-list" v-if="list.length">
              <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells" >
                  <div class="weui-cell public-prizes-history-item" v-for="(item, index) in list" :key="index" @click="willGoUpload(item.answerIdentifier)">
                    <div class="weui-cell__bd">
                      <p class="title">{{item.questionnaireTitle}}</p>
                      <p class="remark">答题时间：{{item.createTime | timeFormat('YYYY-MM-DD HH:mm:ss')}}<br /></p>
                      <p class="remark">问卷编号：{{item.questionnaireId}}</p>
                      <p class="remark">答题标识：{{item.answerIdentifier}}</p>
                    </div>
                    <div class="weui-cell__ft">
                      <p class="status text-green" v-if="item.answerStatus === 'C'">完成</p>
                      <p class="status text-green" v-else-if="item.answerStatus === 'P'">进行中</p>
                      <p class="status text-green" v-else-if="item.answerStatus === 'S'">已作答提交</p>
                      <p class="status text-red" v-else-if="item.answerStatus === 'D'">被甄别到提前结束</p>
                      <p class="status text-red" v-else-if="item.answerStatus === 'F'">限额已满</p>
                      <p class="status text-red" v-else-if="item.answerStatus === 'U'">未知</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center margin-top-10 remark" v-else>
              <span class="public-empty-icon-bg"
                ><i class="icon icon-public-empty-box3"></i
              ></span>
              <p>暂无数据</p>
            </div>

            <div :class="['weui-loadmore', 'public-loadmore', {'hidden' : !isShowBottomLoading}]">
              <i class="weui-loading"></i>
              <span class="weui-loadmore__tips">正在加载</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <tabbar :selectedVal="'users'"></tabbar> -->
  </div>
</template>
<script>
import throttle from 'lodash/throttle'
import { getNeedPicList, getPictureStoreCredential } from '../api/index'
export default {
  name: "prizeHistory",
  components: {
  },
  data () {
    return {
      pageSize: 20,
      pagingKey: '',
      // pageCount: 1, //	总共页数
      list: [], // 待上传图片的答题列表
      hasMore: 'N', // 是否还有
      isShowBottomLoading: false
    }
  },
  watch: {
  },
  created () {
    this.doGetNeedPicList()
    setTimeout(() => {
      this.listenScroll()
    }, 1000)
  },
  methods: {
    // 查询列表信息
    async doGetNeedPicList () {
      let params = {
        param: {
          recordQty: this.pageSize
        }
      }
      if (this.pagingKey) {
        params = {
          param: {
            pagingKey: this.pagingKey,
            recordQty: this.pageSize
          }
        }
      }
      let res = {}
      try {
        res = await getNeedPicList(params)
        // this.$toast.loading('加载中', { duration: 100 });
        if (res.code === '0000') {
          // this.$toast.hide()
          const result = res.result
          const list = result.resultList
          this.list.push(...list)
          this.hasMore = result.hasMore
          this.pagingKey = result.pagingKey
        } else {
          // this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        // this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 提交用户信息
    async doGetPictureStoreCredential () {
      const params = { }
      let res = {}
      try {
        res = await getPictureStoreCredential(params)
        if (res.code === '0000') {
          const result = res.result
          const info = {
            submitStatus: 'S',
            needPictureReview: 'Y',
            answerIdentifier: this.answerIdentifier,
            ossRegion: result.region,
            ossAccessKeyId: result.accessKeyId,
            ossKeySecret: result.accessKeySecret,
            ossStsToken: result.temporaryToken,
            ossBucket: result.bucket
          }
          this.$router.push({name: 'uploadPicture', query: info})
        } else {
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        console.log('err:', err)
      }
    },
    willGoUpload (answerIdentifier) {
      this.answerIdentifier = answerIdentifier
      this.doGetPictureStoreCredential()
    },
    // 页面已经滑动到底部
    pageToBottom () {
      // if (this.pageNum < this.pageCount) {
      //   this.pageNum++
      // }
      if (this.hasMore === 'Y') {
        this.pageNum++
      }
    },
    listenScroll () {
      const _this = this
      window.onscroll = throttle(function (e) {
        console.log('e:', e)
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let screenH = document.documentElement?.clientHeight || window.innerHeight
        let bodyH = document.body.scrollHeight
        if ((scrollTop + screenH + 10 - bodyH) > 0  && this.list.length >= this.pageSize) {
          console.log('页面已经滑动到底部')
          _this.doGetNeedPicList()
        }
      }, 500)
    }
  }
};
</script>
<style lang="css" scoped>
.weui-cell__hd img {
  width: 1.5rem;
}
.public-container{
  margin: unset;
}
</style>
